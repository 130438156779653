import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { EmailTemplateMaintenanceConstant } from "./EmailTemplateMaintenanceConstant";

const SCREEN_CONSTANT = EmailTemplateMaintenanceConstant;
export const INITIAL_EMAIL_TEMPLATE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.CUST_CODE,
        field: "customerCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        //pinned: "left",
        //checkboxSelection: true,
        //headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.SUBJECT,
        field: "subject",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CONTENT,
        field: "content",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers);
});
